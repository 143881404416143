// Tag
.ant-tag-magenta {
  .ant-tag-close-icon {
    color: @secondary-1 !important;
  }
}

.ant-tag-red {
  .ant-tag-close-icon {
    color: @danger-1 !important;
  }
}

.ant-tag-gold {
  .ant-tag-close-icon {
    color: @warning-1 !important;
  }
}

.ant-tag-cyan {
  .ant-tag-close-icon {
    color: @info-1 !important;
  }
}

.ant-tag-blue {
  .ant-tag-close-icon {
    color: @primary-1 !important;
  }
}

.ant-tag-green {
  .ant-tag-close-icon {
    color: @success-1 !important;
  }
}

.ant-tag-success {
  .ant-tag-close-icon {
    color: @success-1 !important;
  }
}

.ant-tag-processing {
  .ant-tag-close-icon {
    color: @primary-1 !important;
  }
}

.ant-tag-error {
  .ant-tag-close-icon {
    color: @danger-1 !important;
  }
}

.ant-tag-warning {
  .ant-tag-close-icon {
    color: @warning-1 !important;
  }
}
