.ant-dropdown-trigger.ant-dropdown-link {
  display: inline-flex;
  align-items: center;
  color: @primary-1;

  svg {
    margin-left: 10px;
  }
}

.ant-dropdown-menu {
  padding: 8px 6px;
  border: 1px solid @b-40;
  max-height: 300px;
  overflow: auto;
}

.ant-dropdown-menu-submenu-popup {
  border: none;
}

.ant-dropdown-menu-item {
  &:hover {
    background-color: @primary-4;
  }
}

.ant-dropdown-menu-submenu-title {
  &:hover {
    background-color: @primary-4;
  }
}
