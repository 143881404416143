.slaText {
  margin-left: 10px;
  display: inline-block;
  position: absolute;
}

.greyscale {
  filter: grayscale(1);
}

.searchDropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 162px;
}

.searchInput {
  border-radius: 0;
  min-height: 42px;
}

.searchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-height: 42px;
}

.lineHeight-3 {
  line-height: 3;
}

.mapMarker {
  font-size: 26px;
}

.customTitle {
  width: 70%;
  margin-top: 5px;
}

.featuresTag {
  border: none;
  margin-bottom: 7px;
}

.featuresText {
  display: inline;
  font-weight: normal;
}

.mapContainerProperty {
  height: 300px;
  width: 400px;
}

.arrowBtnLeft {
  border: none;
  position: absolute;
  top: 200px;
  left: -75px;
}

.arrowBtnRight {
  border: none;
  position: absolute;
  top: 200px;
  right: -75px;
}

.imgSwitch {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: -11px;
}

.affixShadow {
  background-color: #fff;
  padding-bottom: 20px;
  padding-top: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}
