.@{class-prefix}-content-main {
  margin: 0 0px;
  min-height: calc(100vh - 173px);

  @media @sm {
    margin: 24px 16px 0;
    min-height: calc(100vh - 144px);
    margin: 0 16px;
  }
}
