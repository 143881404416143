.@{class-prefix}-color-wrapper-xl {
  height: 60px;
  width: 90px;
}

.@{class-prefix}-color-wrapper-sm {
  height: 20px;
  width: 30px;
}

.@{class-prefix}-color-wrapper {
  height: 60px;
  width: 60px;
}

.border {
  border: 1px solid @b-40;
}
