.systemUserFormRow {
  margin-bottom: 15px;
}

.systemUserFormHelperText {
  margin-left: 5px;
}

.systemUserFormSelect {
  width: 100%;
}

.systemUserButton {
  width: 100%;
}

.systemUserLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}
