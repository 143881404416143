.ant-tag {
  padding: 0 16px;
}

// Colors
.ant-tag-magenta {
  background: @secondary-4;

  border-color: @secondary-1;
  color: @secondary-1;
}

.ant-tag-red {
  background: @danger-4;

  border-color: @danger-1;
  color: @danger-1;
}

.ant-tag-gold {
  background: @warning-4;

  border-color: @warning-1;
  color: @warning-1;
}

.ant-tag-cyan {
  background: @info-4;

  border-color: @info-1;
  color: @info-1;
}

.ant-tag-blue {
  background: @primary-4;

  border-color: @primary-1;
  color: @primary-1;
}

.ant-tag-green {
  background: @success-4;

  border-color: @success-1;
  color: @success-1;
}

// Status
.ant-tag-success {
  background: @success-4;

  border-color: @success-1;
  color: @success-1;
}

.ant-tag-processing {
  background: @primary-4;

  border-color: @primary-1;
  color: @primary-1;
}

.ant-tag-error {
  background: @danger-4;

  border-color: @danger-1;
  color: @danger-1;
}

.ant-tag-warning {
  background: @warning-4;

  border-color: @warning-1;
  color: @warning-1;
}
