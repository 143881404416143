.loadingDiv {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapContainer {
  height: 100vh;
  width: 100vw;
}

.propsureActionsContainer {
  margin-top: 20px;
}

.propsureInformationContainer {
  background-color: #cfe2ff;
  border-style: solid;
  border-color: #b6d4fe;
  border-width: 2px;
  width: 100%;
  height: 100%;
}

.propsureInformationRow {
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.propsureInformationBoldText {
  font-weight: bold;
  color: #084298;
}

.propsureInformationText {
  color: #084298;
}

.propsureInformationButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.propsureInformationButton {
  width: 80%;
}

.geotaggingDropdownSelect {
  margin-left: 5%;
  width: 90%;
  margin-bottom: 5vh;
}

.geotaggingDropdownButton {
  margin-left: 5%;
  width: 25%,;
  margin-bottom: 5vh;
}
