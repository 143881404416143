.ant-picker-focused {
  border-color: @primary-2;
  box-shadow: @input-focus;
}

.ant-picker-time-panel-column {
  &::-webkit-scrollbar {
    width: 0;
  }
}
