.ant-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 60%;
    margin-left: 2px !important;
  }
}

.ant-avatar-group-popover .ant-popover-inner-content .ant-avatar {
  margin: 0 auto;

  + .ant-avatar {
    margin-top: 10px;
  }
}
